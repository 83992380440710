import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import homeIcon from '~/components/HomePageBanner/images/home-icon.png';
import alertsIcon from '~/components/HomePageBanner/images/alerts.png';
// import inventoryIcon from '~/components/HomePageBanner/images/inventory.png';
import buyerIcon from '~/components/HomePageBanner/images/buyer.png';
import useWindowSize from '~/utils/useWindowSize';
import searchAgentIcon from './images/search-agent-icon.png';

import styles from './HomePageBanner.module.scss';

type BannerItemType = {
    imageUrl: string;
    redirectPath?: string;
    description: string;
    height: number;
    mobileHeight: number;
    width: number;
    mobileWidth: number;
};

const bannerItems: BannerItemType[] = [
    {
        imageUrl: homeIcon,
        height: 59,
        width: 68,
        mobileWidth: 45,
        mobileHeight: 39,
        description: 'Access pre-portal, passive and private properties',
    },

    {
        imageUrl: alertsIcon,
        height: 63,
        width: 46,
        mobileWidth: 28,
        mobileHeight: 39,
        description: 'AI-powered alerts to save you time',
    },
    {
        redirectPath: '/contact-agent',
        imageUrl: searchAgentIcon,
        height: 70,
        width: 70,
        mobileHeight: 39,
        mobileWidth: 36,
        description: 'Find an Agent',
    },
    // {
    //     imageUrl: inventoryIcon,
    //     height: 60,
    //     width: 57,
    //     mobileHeight: 39,
    //     mobileWidth: 36,
    //     description: 'Curated inventory for investors',
    // },
    {
        imageUrl: buyerIcon,
        height: 60,
        width: 56,
        mobileHeight: 39,
        mobileWidth: 36,
        description: 'Buyers agents and professionals',
    },
];

const HomePageBanner = () => {
    const { width: screenWidth } = useWindowSize();
    return (
        <div className={`flex w-full justify-center ${styles.gradientBlueBg}`}>
            <div className="flex w-full max-w-screen-xl items-center px-6 py-11 sm:px-16">
                <div className="grid w-full grid-cols-2 gap-4 sm:gap-14 lg:grid-cols-4">
                    {/* <div className="flex h-full w-full"> */}
                    {bannerItems.map(
                        ({ redirectPath, imageUrl, description, height, width, mobileHeight, mobileWidth }) => {
                            const imgHeight = screenWidth && screenWidth < 640 ? mobileHeight : height;
                            const imgWidth = screenWidth && screenWidth < 640 ? mobileWidth : width;

                            return (
                                <>
                                    {redirectPath ? (
                                        <Link href={redirectPath}>
                                            <a target="_blank">
                                                <div
                                                    key={imageUrl}
                                                    className="flex flex-col gap-4 rounded-2xl bg-white p-4 hover:bg-white/80 sm:flex-row"
                                                    style={{ boxShadow: 'rgba(0, 0, 0, 0.02)' }}
                                                >
                                                    <div className="relative flex items-center">
                                                        <Image
                                                            src={imageUrl}
                                                            alt={description}
                                                            height={imgHeight}
                                                            width={imgWidth}
                                                        />
                                                    </div>
                                                    <p className="m-auto w-full flex-1 text-xs text-slate-500 sm:text-sm">
                                                        {description}
                                                    </p>
                                                </div>
                                            </a>
                                        </Link>
                                    ) : (
                                        <div
                                            key={imageUrl}
                                            className="flex flex-col gap-4 rounded-2xl bg-white p-4 sm:flex-row"
                                            style={{ boxShadow: 'rgba(0, 0, 0, 0.02)' }}
                                        >
                                            <div className="relative flex items-center">
                                                <Image
                                                    src={imageUrl}
                                                    alt={description}
                                                    height={imgHeight}
                                                    width={imgWidth}
                                                />
                                            </div>
                                            <p className="m-auto w-full flex-1 text-xs text-slate-500 sm:text-sm">
                                                {description}
                                            </p>
                                        </div>
                                    )}
                                </>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomePageBanner;
